import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ParkingManagerActions } from './parking.actions';

import { map, mergeMap } from 'rxjs/operators';
import { CouponList, CouponService } from '../services/parking.service';
import { CouponFormData } from '../interfaces/parking.interface';

@Injectable()
export class ParkingManagerEffects {
  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParkingManagerActions.list),
      map(
        ({ type: _type, ...params }: CouponList & { type: string }) => params,
      ),
      mergeMap((params) => {
        return this.couponService
          .list(params)
          .pipe(map((data) => ParkingManagerActions.listSuccess({ data })));
      }),
    ),
  );

  getCoupon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParkingManagerActions.coupon),
      map(({ type: _type, ...params }: { type: string; id: number }) => params),
      mergeMap((params) => {
        return this.couponService
          .getCoupon(params)
          .pipe(map((data) => ParkingManagerActions.couponSuccess({ data })));
      }),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParkingManagerActions.deleteParkingManager),
      map(({ ids }: { ids: number[]; type: string }) => ({
        ids,
      })),
      mergeMap((param) => {
        return this.couponService
          .deleteCoupon(param.ids)
          .pipe(map((data) => ParkingManagerActions.deleteSuccess({ data })));
      }),
    ),
  );

  createParkingManager$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParkingManagerActions.createParkingManager),
      map(
        ({
          type: _type,
          ...params
        }: Partial<CouponFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.couponService
          .createParkingManager(params as Partial<CouponFormData>)
          .pipe(map((data) => ParkingManagerActions.createSuccess({ data })));
      }),
    ),
  );

  updateParkingManager$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParkingManagerActions.updateParkingManager),
      map(
        ({
          type: _type,
          ...params
        }: Partial<CouponFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.couponService
          .updateParkingManager(params as Partial<CouponFormData>)
          .pipe(map((data) => ParkingManagerActions.updateSuccess({ data })));
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private couponService: CouponService,
  ) {}
}

import { createActionGroup, props, emptyProps } from '@ngrx/store';
import { CouponList } from '../services/parking.service';
import { CouponFormData } from '../interfaces/parking.interface';

export const ParkingManagerActions = createActionGroup({
  source: 'Coupon Component',
  events: {
    list: props<CouponList>(),
    listSuccess: props<{ data: any }>(),
    listFail: props<{ error: any }>(),
    coupon: props<{ id: number }>(),
    couponSuccess: props<{ data: any }>(),
    couponFail: props<{ error: any }>(),
    deleteParkingManager: props<{ ids: number[] }>(),
    deleteSuccess: props<{ data: any }>(),
    deleteFail: props<{ error: any }>(),
    createParkingManager: props<Partial<CouponFormData>>(),
    createSuccess: props<{ data: any }>(),
    createFail: props<{ error: any }>(),
    updateParkingManager: props<Partial<CouponFormData>>(),
    updateSuccess: props<{ data: any }>(),
    updateFail: props<{ error: any }>(),
  },
});

import { createReducer, on } from '@ngrx/store';

import { GiftCardActions } from './giftCard.actions';
import { GiftCardState } from '../interfaces/giftCard.interface';

const initialState: GiftCardState = {
  giftCards: null,
  loading: false,
  error: null,
  giftCard: null,
  giftCardLoading: false,
  giftCardError: null,
  deleteLoading: false,
  deleteCoupon: false,
  deleteError: null,
  createLoading: false,
  createCoupon: false,
  createError: null,
  createSuccess: false,
  updateLoading: false,
  updateCoupon: false,
  updateError: null,
  updateSuccess: false,
};

export const giftCardReducer = createReducer(
  initialState,
  on(GiftCardActions.list, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(GiftCardActions.listSuccess, (state, { data }) => ({
    ...state,
    giftCards: data,
    loading: true,
    error: null,
  })),
  on(GiftCardActions.listFail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(GiftCardActions.giftCard, (state) => ({
    ...state,
    giftCard: null,
    giftCardLoading: false,
    giftCardError: null,
  })),
  on(GiftCardActions.giftCardSuccess, (state, { data }) => ({
    ...state,
    giftCard: data,
    giftCardLoading: true,
    giftCardError: null,
  })),
  on(GiftCardActions.giftCardFail, (state, { error }) => ({
    ...state,
    giftCard: null,
    giftCardLoading: false,
    giftCardError: error,
  })),
  on(GiftCardActions.cancelGiftCard, (state) => ({
    ...state,
    deleteLoading: false,
    deleteError: null,
    deleteGiftCards: false,
  })),
  on(GiftCardActions.cancelSuccess, (state) => ({
    ...state,
    deleteGiftCards: true,
    deleteLoading: true,
    deleteError: null,
  })),
  on(GiftCardActions.cancelFail, (state, { error }) => ({
    ...state,
    deleteLoading: false,
    deleteError: error,
    deleteGiftCards: false,
  })),
  on(GiftCardActions.createGiftCard, (state) => ({
    ...state,
    createLoading: false,
    createError: null,
    createCoupon: false,
  })),
  on(GiftCardActions.createSuccess, (state) => ({
    ...state,
    createCoupon: true,
    createLoading: true,
    createError: null,
  })),
  on(GiftCardActions.createFail, (state, { error }) => ({
    ...state,
    createLoading: false,
    createError: error,
    createCoupon: false,
  })),
  on(GiftCardActions.updateGiftCard, (state) => ({
    ...state,
    updateLoading: false,
    updateError: null,
    updateCoupon: false,
  })),
  on(GiftCardActions.updateSuccess, (state) => ({
    ...state,
    updateCoupon: true,
    updateLoading: true,
    updateError: null,
  })),
  on(GiftCardActions.updateFail, (state, { error }) => ({
    ...state,
    updateLoading: false,
    updateError: error,
    updateCoupon: false,
  })),
);

export const giftCardKey = 'giftCard';

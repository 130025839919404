import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EntityFormData } from '../interfaces/client.interface';
export interface TableList {
  offset: number;
  limit: number;
  id?: number;
  user_id?: number;
  client_id?: number;
  on_date_ts?: string;
  payment_id?: number;
  status?: string;
  total_sum?: number;
  delivery_sum?: number;
}

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(private http: HttpClient) {}

  public getClient(params: { id: number }) {
    return this.http.get(
      `${environment.apiUrl}/clients/${params.id}`,
    );
  }

  public getClientSubscription(params: { id: number }) {
    return this.http.get(
      `${environment.apiUrl}/clients/${params.id}/subscription`,
    );
  }

  public update(params: Partial<EntityFormData>) {
    return this.http.put(`${environment.apiUrl}/clients/`, { ...params });
  }

  public deleteUser(params: { id: number, userId:string }) {
    console.log(params);
    return this.http.delete(
      `${environment.apiUrl}/crm/user/${params.id}/${params.userId}`,
    );
  }

  // public list(params: TableList) {
  //   const httpParams: HttpParams = new HttpParams({
  //     fromObject: { ...params },
  //   });
  //   return this.http.get(`${environment.apiUrl}/clients/`, {
  //     params: httpParams,
  //   });
  // }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { GiftCardActions } from './giftCard.actions';

import { map, mergeMap } from 'rxjs/operators';
import { GiftCardList, GiftCardService } from '../services/giftCard.service';

@Injectable()
export class GiftCardEffects {
  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GiftCardActions.list),
      map(
        ({ type: _type, ...params }: GiftCardList & { type: string }) => params,
      ),
      mergeMap((params) => {
        return this.giftCardService
          .list(params)
          .pipe(map((data) => GiftCardActions.listSuccess({ data })));
      }),
    ),
  );

  getGiftCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GiftCardActions.giftCard),
      map(({ type: _type, ...params }: { type: string; id: number }) => params),
      mergeMap((params) => {
        return this.giftCardService
          .getGiftCard(params)
          .pipe(map((data) => GiftCardActions.giftCardSuccess({ data })));
      }),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GiftCardActions.cancelGiftCard),
      map(({ id }: { id: string; type: string }) => ({
        id,
      })),
      mergeMap((param) => {
        return this.giftCardService
          .cancelGiftCard(param.id)
          .pipe(map((data) => GiftCardActions.cancelSuccess({ data })));
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private giftCardService: GiftCardService,
  ) {}
}

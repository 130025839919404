import { createActionGroup, props, emptyProps } from '@ngrx/store';
import { GiftCardList } from '../services/giftCard.service';
import { GiftCardFormData } from '../interfaces/giftCard.interface';

export const GiftCardActions = createActionGroup({
  source: 'GiftCard Component',
  events: {
    list: props<GiftCardList>(),
    listSuccess: props<{ data: any }>(),
    listFail: props<{ error: any }>(),
    giftCard: props<{ id: number }>(),
    giftCardSuccess: props<{ data: any }>(),
    giftCardFail: props<{ error: any }>(),
    cancelGiftCard: props<{ id: string }>(),
    cancelSuccess: props<{ data: any }>(),
    cancelFail: props<{ error: any }>(),
    createGiftCard: props<Partial<GiftCardFormData>>(),
    createSuccess: props<{ data: any }>(),
    createFail: props<{ error: any }>(),
    updateGiftCard: props<Partial<GiftCardFormData>>(),
    updateSuccess: props<{ data: any }>(),
    updateFail: props<{ error: any }>(),
  },
});

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GroupFormData } from '../interfaces/groups.interface';

export interface GroupList {
  offset: number;
  limit: number;
  name?: string;
  id?: string;
  supplier?: number;
  active?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class GroupsService {
  constructor(private http: HttpClient) {}

  public list(params: GroupList) {
    const httpParams: HttpParams = new HttpParams({
      fromObject: { ...params },
    });

    return this.http.get(`${environment.apiUrl}/groups/`, {
      params: httpParams,
    });
  }

  public suppliers(params: { offset: number; limit: number; name?: string }) {
    return this.http.get(`${environment.apiUrl}/groups/suppliers`, {
      params,
    });
  }

  public getGroup(params: { id: number }) {
    return this.http.get(
      `${environment.apiUrl}/groups/getGroupById/${params.id}`,
    );
  }

  public delete(ids: number[]) {
    return this.http.delete(`${environment.apiUrl}/groups/`, { body: { ids } });
  }

  public create(params: Partial<GroupFormData>) {
    return this.http.post(`${environment.apiUrl}/groups/`, params);
  }

  public update(params: Partial<GroupFormData>) {
    return this.http.put(`${environment.apiUrl}/groups/`, { ...params });
  }
}
